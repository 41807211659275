/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
class TeamSettingsController extends BaseController {
  constructor() {
    super();

    this.$$import(this.$$dependencies([
      'seoService',
      'sdk',
    ]));

    this.props = {
      account: {
        teamName: '',
        teamType: 0,
        teamIsPlus: false,
        teamIsTrial: false,
      },
      settings: {
        displayComments: false,
        displayTeamName: false,
        generalComments: true,
        privateComments: false,
        senderEmail: '',
        editComments: false,
        ownerCanDeleteComments: true,
        reference: false,
        canAddRepliesInStatusTodosRequested: true,
        messageToOwners: true,
        publicProofs: false,
        defaultProofIsPublic: false,
        usePreviousVersionDueDate: false,
        canLeaveApprovedWithChanges: false,
        enableChecklists: false,
        enableWebhooks: false,
        enableProofMagicAccess: false,
        suggestionsIncludeTeamMembers: false,
        enableCommentVisibility: false,
        defaultCommentVisibility: false,
        configureViewOnlyReviewers: false,
        enableViewOnlyReviewers: false,
      },
      notifications: {
        ownerAddedByAdmin: true, // By default it's true
      },
      onChange: this.updateSettings,
      onUpdateNotification: this.updateNotification,
      onUpdateSenderEmail: this.updateSenderEmail,
    };

    this.$$.seoService.set({
      title: 'domain-admin.menu.settings',
      pageTitle: 'domain-admin.menu.settings',
      translateTitle: true,
      translatePageTitle: true,
    });

    this.sendRequest('load');
    this.sendNotificationRequest('load');
  }

  setDisabledRepliesInStatusesFields() {
    const statusArray = this.props.settings.disableReplyingToCommentsInStatuses || [];
    this.props.settings.canAddRepliesInStatusTodosRequested = !statusArray.includes(50);
  }

  getDisabledRepliesInStatusesArray() {
    let statusArray = this.props.settings.disableReplyingToCommentsInStatuses || [];
    if (!this.props.settings.canAddRepliesInStatusTodosRequested) {
      statusArray.push(50, 60);
    } else {
      statusArray = statusArray.filter(status => status !== 50 && status !== 60)
    }
    return window.generalfunctions_unique(statusArray);
  }

  setCanLeaveApprovedWithChanges() {
    const { APPROVED_WITH_CHANGES } = window.__pageproof_quark__.sdk.Enum.Decision;
    this.props.settings.canLeaveApprovedWithChanges = this.props.settings.allowedDecisions.includes(APPROVED_WITH_CHANGES);
  }

  getAllowedDecisions() {
    const { APPROVED, SEND_CHANGES, APPROVED_WITH_CHANGES } = window.__pageproof_quark__.sdk.Enum.Decision;
    let decisionArray = this.props.settings.allowedDecisions || [APPROVED, SEND_CHANGES];

    if (this.props.settings.canLeaveApprovedWithChanges) {
      decisionArray.push(APPROVED_WITH_CHANGES);
    } else {
      decisionArray = decisionArray.filter(decision => decision !== APPROVED_WITH_CHANGES);
    }

    return window.generalfunctions_unique(decisionArray);
  }

  sendRequest(type, params) {
    return this.$$.sdk.admin()
      .then(admin => admin.team.features[type](params))
      .then((data) => {
        if (data && type === 'load') {
          this.props.account.teamName = data.accounts.teamName;
          this.props.account.teamType = data.accounts.teamType;
          this.props.account.teamIsPlus = data.accounts.teamIsPlus;
          this.props.account.teamIsTrial = data.accounts.teamIsTrial;
          this.props.settings.displayComments = data.emails.displayComments;
          this.props.settings.displayTeamName = data.emails.displayTeamName;
          this.props.settings.suggestionsIncludeTeamMembers = data.addressBook.suggestionsIncludeTeamMembers;
          this.props.settings.configureSuggestionsIncludeTeamMembers = data.addressBook.configureSuggestionsIncludeTeamMembers;
          this.props.settings.enableWebhooks = data.webhooks.enabled;
          this.props.settings.configureWebhooks = data.webhooks.configurable;
          this.props.settings.enableProofMagicAccess = data.proofs.enableProofMagicAccess;
          this.props.settings.generalComments = data.comments.generalComments;
          this.props.settings.configurePrivateComments = data.comments.configurePrivateComments;
          this.props.settings.privateComments = data.comments.privateComments;
          this.props.settings.senderEmail = data.emails.senderEmail;
          this.props.settings.editComments = data.comments.editComments;
          this.props.settings.ownerCanDeleteComments = data.comments.ownerCanDeleteComments;
          this.props.settings.disableReplyingToCommentsInStatuses = data.comments.disableReplyingToCommentsInStatuses;
          this.setDisabledRepliesInStatusesFields();
          this.props.settings.messageToOwners = data.proofs.messageToOwners;
          this.props.settings.reference = data.proofs.reference;
          this.props.settings.publicProofs = data.proofs.publicProofs;
          this.props.settings.defaultProofIsPublic = data.proofs.defaultIsPublic;
          this.props.settings.usePreviousVersionDueDate = data.proofs.usePreviousVersionDueDate;
          this.props.settings.allowedDecisions = data.proofs.allowedDecisions;
          this.setCanLeaveApprovedWithChanges();
          this.props.settings.enableChecklists = data.proofs.enableChecklists;
          this.props.settings.configureCommentVisibility = data.comments.configureCommentVisibility;
          this.props.settings.enableCommentVisibility = data.comments.enableCommentVisibility;
          this.props.settings.defaultCommentVisibility = data.comments.defaultCommentVisibility === 'commentCreatorOrProofTeam';
          this.props.settings.configureViewOnlyReviewers = data.workflows.configureViewOnlyReviewers;
          this.props.settings.enableViewOnlyReviewers = data.workflows.enableViewOnlyReviewers;
          this.props.settings.enableIntelligence = data.intelligence.enableIntelligence;
          this.props.settings.enableSmartTags = data.intelligence.enableSmartTags;
        }
      });
  }

  sendNotificationRequest(type, params) {
    return this.$$.sdk.admin()
      .then(admin => admin.team.preferences.notifications[type](params))
      .then((data) => {
        if (data && type == 'load') {
          this.props.notifications.ownerAddedByAdmin = data.ownerAddedByAdmin;
        }
      });
  }

  updateSettings = (featureType, settingsType) => {
    const updatedValue = !this.props.settings[settingsType];
    this.props.settings[settingsType] = updatedValue;

    if (settingsType === 'canAddRepliesInStatusTodosRequested') {
      settingsType = 'disableReplyingToCommentsInStatuses';
      this.props.settings.disableReplyingToCommentsInStatuses = this.getDisabledRepliesInStatusesArray();
    }

    if (settingsType === 'defaultIsPublic') {
      this.props.settings.defaultProofIsPublic = !this.props.settings.defaultProofIsPublic;
      this.props.settings.defaultIsPublic = this.props.settings.defaultProofIsPublic;
    }

    if (settingsType === 'canLeaveApprovedWithChanges') {
      settingsType = 'allowedDecisions';
      this.props.settings.allowedDecisions = this.getAllowedDecisions();
    }

    if (settingsType === 'enableWebhooks') {
      settingsType = 'enabled';
    }

    if (settingsType === 'defaultCommentVisibility') {
      this.props.settings[settingsType] = updatedValue ? 'commentCreatorOrProofTeam' : null;
    }

    this.sendRequest('update', {
      [featureType]: {
        [settingsType]: this.props.settings[settingsType],
      },
    });
  }

  updateNotification = (notificationType) => {
    this.props.notifications[notificationType] = !this.props.notifications[notificationType];
    this.sendNotificationRequest('set', {
      [notificationType]: this.props.notifications[notificationType],
    });
  }

  updateSenderEmail = email => this.$$.sdk.admin().then(admin => admin.team.features.setSenderEmail(email));
}

app.controller('TeamSettingsController', TeamSettingsController);
